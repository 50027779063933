<template>
  <SettingsCard
    title="Versand von Ratgebern"
    subtitle="Legen Sie fest, welche Ratgeber Sie Ihren Kunden auch in gedruckter Form anbieten möchten."
    icon="mdi-email-fast-outline"
    :is-default-open="isDefaultOpen"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              Modus auswählen<br>
            </span>
            <span class="grey--text">
              Sie können Ihre Ratgeber entweder nur als online PDF Dokumente anbieten, oder auch den postalischen Versand der Ratgeber aktivieren.
              Nach Aktivierung werden Sie benachrichtigt, welchen Ratgeber ein Kontakt in Druckform wünscht.
              Diesen können Sie dann postalisch versenden oder persönlich abliefern.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-radio-group
            v-model="type"
            label="Wie möchten Sie Ihre Ratgeber anbieten?"
          >
            <v-radio
              v-for="{ label, value } in shippingOptions"
              :key="value"
              :label="label"
              :value="value"
            />
          </v-radio-group>

          <div
            v-if="loading"
            class="mt-2 d-flex justify-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            />
          </div>

          <div
            v-if="setToTypeNone && !loading"
            class="d-flex"
          >
            <v-icon
              color="primary"
            >
              mdi-information
            </v-icon>
            <div class="ml-2">
              <b>Fertig!</b>
              Die Versandoption wird auf den Landingpages nicht angezeigt
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row v-if="type === 'MANUAL' && !loading">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              Dokumente auswählen<br>
            </span>
            <span class="grey--text">
              Schalten Sie den postalischen Versand für all Ihre Ratgeber ein, oder erstellen Sie eine
              individuelle Auswahl von Dokumenten, bei denen Sie den postalischen Versand anbieten wollen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <v-radio-group
              v-model="allGuides"
              hide-details
              label="Für welche Ratgeber soll die Versandoption angezeigt werden?"
            >
              <v-radio
                label="Für alle Ratgeber"
                :value="true"
              />
              <v-radio
                label="Nur für bestimmte Ratgeber"
                :value="false"
              />
            </v-radio-group>

            <v-expand-transition>
              <div
                v-if="!allGuides"
                class="pt-2"
              >
                <LabelSwitch
                  v-for="({name, id, slug}) in guides"
                  :key="id"
                  :value="landingpageValues[slug]"
                  class="ma-2 switch"
                  min-height="100px"
                  :label="name"
                  @input="updatePostalShippingSetting($event,slug)"
                />
              </div>
            </v-expand-transition>
          </div>
        </v-col>
      </v-row>

      <v-alert
        icon="mdi-lightbulb-on-20"
        prominent
        text
        type="info"
        class="mt-4"
      >
        <p class="ma-0 font-weight-bold">
          Hinweis:
        </p>
        <span class="pb-2 pt-4">Ratgeber selbst versenden: </span>
        <p>
          Durch den Erhalt der Adresse können Sie entscheiden, ob Sie einen Ratgeber per Post versenden oder diesen selbst ausliefern.
          Eine Chance direkt ein erstes Gespräch zu beginnen.
        </p>
        <p>
          Sollten Sie kein gedrucktes Exemplar des gewünschten Ratgebers vorrätig haben,
          kann zu viel Zeit vergehen, bis Sie den Ratgeber ausliefern können und der Kontakt hat sich inzwischen anderweitig informiert.
        </p>
      </v-alert>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '../../components/SettingsCard.vue'
import UPDATE_POSTAL_SHIPPING from './queries/UpdatePostalShipping.gql'
import LabelSwitch from '@/components/LabelSwitch.vue'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: { SettingsCard, LabelSwitch },
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    guides: {
      type: Array,
      default: () => ([])
    },
    refetchSettings: {
      type: Function,
      default: () => {}
    },
    isDefaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      type: this.settings.lead.postalShipping.type,
      loading: false,
      setToTypeNone: false,
      allGuides: this.settings.lead.postalShipping.allGuides,
      shippingOptions: [
        { label: 'Nur online als Pdf - kein Postversand', value: 'NONE' },
        { label: 'Selbst versenden (kostenlos)', value: 'MANUAL' }
      ],
      landingpageValues: this.guides
        .reduce((obj, { slug }) => ({ ...obj, [slug]: this.settings.lead.postalShipping.activeLandingpages.includes(slug) }), {})
    }
  },
  watch: {
    async type (value) {
      this.setToTypeNone = value === 'NONE'
      this.loading = true
      await this.saveSettings()
      this.finishLoading(700)
    },
    async allGuides (value) {
      if (!value && Object.entries(this.landingpageValues).filter(([_, value]) => value).length === 0) {
        this.landingpageValues = this.guides.reduce((obj, { slug }) => ({ ...obj, [slug]: true }), {})
      }
      await this.saveSettings()
    }
  },
  methods: {
    updatePostalShippingSetting (value, slug) {
      this.landingpageValues[slug] = value
      this.saveSettings()
    },
    finishLoading (milliseconds) {
      setTimeout(() => { this.loading = false }, milliseconds)
    },
    async saveSettings () {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_POSTAL_SHIPPING,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              type: this.type,
              allGuides: this.allGuides,
              activeLandingpages: Object.entries(this.landingpageValues).filter(([_, value]) => value).map(([slug]) => slug)
            }
          }
        })
        this.refetchSettings()
      } catch (e) {
        this.loading = false
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    }
  }
}
</script>
<style scoped>
.switch {
  min-height: 40px;
}
</style>
