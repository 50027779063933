<template>
  <div class="w-full">
    <v-form
      ref="addressForm"
      v-model="isFormValid"
      @change="error = ''"
    >
      <v-row>
        <v-text-field
          v-model.lazy="companyData.name"
          outlined
          label="Firmenname"
          placeholder="Max Makler Immobilien"
          :rules="[rules.length({max: 90}) ]"
          :loading="isLoading.name"
          @input="(val) => update('name', val)"
        />
      </v-row>
      <v-row>
        <v-text-field
          v-model.lazy="companyData.street"
          outlined
          label="Straße und Hausnummer"
          placeholder="Musterstraße 3"
          :loading="isLoading.street"
          @input="(val) => update('street', val)"
        />
      </v-row>
      <v-row class="d-flex justify-space-between">
        <v-col
          cols="12"
          md="4"
          class="pr-0 pa-0 pr-md-2"
        >
          <v-text-field
            v-model.lazy="companyData.zip"
            outlined
            label="PLZ"
            type="number"
            placeholder="12345"
            :rules="[rules.zip, rules.length({min: 4, max: 5})]"
            :loading="isLoading.zip"
            @input="(val) => update('zip', val)"
          />
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="pr-0 pa-0"
        >
          <v-text-field
            v-model.lazy="companyData.city"
            outlined
            label="Stadt"
            placeholder="Musterstadt"
            :loading="isLoading.city"
            @input="(val) => update('city', val)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-select
          v-model.lazy="companyData.country"
          :items="countries"
          label="Land"
          outlined
          required
          :rules="[rules.required]"
          @change="(val) => update('country', val)"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { zip, length, required } from '@/lib/validation'
import { COUNTRIES } from './labels'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      companyData: {
        name: this.name,
        street: this.address?.street,
        zip: this.address?.zip,
        city: this.address?.city,
        country: this.address?.country?.toUpperCase()
      },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        name: false,
        street: false,
        city: false,
        zip: false,
        country: false
      },
      isFormValid: false
    }
  },
  watch: {
    name () {
      this.companyData.name = this.name
    },
    address () {
      this.companyData.street = this.address.street
      this.companyData.zip = this.address.zip
      this.companyData.city = this.address.city
      this.companyData.country = this.address.country?.toUpperCase()
    }
  },
  created () {
    this.countries = COUNTRIES
    this.rules = { zip, length, required }
  },
  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },
  methods: {
    async update (name, value) {
      this.companyData[name] = value
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading[name] = true
        this.spinnerTimeout = setTimeout(async () => {
          const isFormValid = await this.$refs.addressForm.validate()
          if (isFormValid) {
            this.$emit('saveSettings', { ...this.companyData })
          }
          this.isLoading[name] = false
        }, 1000)
      }, 2000)
    }
  }
}
</script>
