<template>
  <v-container
    class="layout-container pa-0"
  >
    <ContactSettings
      :settings="settings"
      :company="company"
    />

    <v-divider
      class="my-4"
    />

    <WebSettings
      :settings="settings"
      :company="company"
      class="my-4"
    />

    <v-divider
      class="my-4"
    />

    <ImageSettings
      :settings="settings"
      :company="company"
    />
  </v-container>
</template>

<script>
import ContactSettings from './ContactSettings.vue'
import WebSettings from './WebSettings.vue'
import ImageSettings from './ImageSettings.vue'
import featureMixin from '@/mixins/feature'
import SETTINGS from './queries/Settings.gql'
import COMPANY from './queries/CompanyData.gql'

export default {
  components: {
    ContactSettings,
    WebSettings,
    ImageSettings
  },
  mixins: [featureMixin],
  apollo: {
    settings: {
      query: SETTINGS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
