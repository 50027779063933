<template>
  <SettingsCard
    title="Webauftritt"
    subtitle="Ihr Webauftritt kann das Vertrauen der Besuchern Ihrer Webseite fördern, wenn Sie Datenschutz, Impressum und mehr angeben."
    icon="mdi-web"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Wichtige Links
              </span>
            </div>
            <span class="grey--text">
              Bitte halten Sie diese Links stets aktuell.<br>
              Wir sind rechtlich dazu verpflichtet, die Links zu Impressum und den Datenschutzbestimmungen auf den Landingpages anzugeben,
              die wir in Ihrem Namen erstellen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-center px-0 px-md-2 mt-4"
        >
          <LinkData
            v-bind="companyLinkData"
            @updateSettings="saveSettings"
          />
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '../../components/SettingsCard.vue'
import LinkData from '@/components/forms/LinkData'
import UPDATE_COMPANY_LINKS from './queries/UpdateCompanyLinks.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    SettingsCard,
    LinkData
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyLinkData () {
      const { links } = this.company
      return { links }
    }
  },
  methods: {
    async saveSettings (linksData) {
      const { __typename, ...links } = linksData
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_LINKS,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              links
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    }
  }
}
</script>
