<template>
  <SettingsCard
    title="Verifizierungen"
    :subtitle="subtitle"
    icon="mdi-home-variant"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <Feature
        :feature-slug="featureNames.PLOT_VALUATION"
      >
        <v-row v-if="isExpert">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  Grundstücksbewertung
                </span>
              </div>
              <span class="grey--text">
                Sie möchten die Grundstücksbewertung zusätzlich als Teil der Immobilienbewertung nutzen?
                Zusätzlich zu den bisherigen Optionen wird dann auch "Grundstück" angezeigt.
              </span>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center px-0 px-md-2"
          >
            <div
              class="d-flex flex-column"
              :style="{'max-width':$vuetify.breakpoint.smAndDown ? '100%' : '70%'}"
            >
              <div class="d-flex">
                <span>Grundstücksbewertung hinzufügen: </span>
                <v-switch
                  v-model="isPlotValuationInValuation"
                  class="ma-0 mx-2"
                  dense
                  hide-details
                  @change="updatePlotValuationInValuation"
                />
              </div>
              <v-img
                :src="plotValuationSettingSrc"
                alt="plot valuation image"
                contain
                max-width="100%"
              />
            </div>
          </v-col>
        </v-row>
      </Feature>
      <Feature
        v-slot="{feature}"
        :feature-slug="featureNames.VALUATION"
      >
        <v-row v-if="feature.config.isPhoneNumberVerificationEnabled && feature.config.canChangePhoneNumberVerification">
          <v-col
            cols="12"
            sm="6"
            class="px-0 px-md-2"
          >
            <div>
              <div class="d-flex align-center">
                <span class="font-weight-bold">
                  Telefonnummer-Verifizierung
                </span>
              </div>
              <span class="grey--text">
                Durch die Aktivierung werden Telefonnummern per SMS oder Anruf verifiziert.
                So können Sie sicher sein, dass ein Kontakt wirklich die korrekte Telefonnummer angibt.
                Überspringt der Kontakt die Verifizierung, erhält er zwar die Bewertung per E-Mail, kann das PDF aber nicht öffnen.
                In der Detailansicht des Kontaktes in der App können Sie sehen, bei wem dies der Fall war.<br>
              </span>
              <p class="ma-0 mt-2 grey--text">
                <b>Achtung:</b> Die zusätzliche Hürde der Verifizierung kann dazu führen, dass potenzielle Verkäufer die Immobilienbewertung abbrechen. <br>
                <b>Ihre Entscheidung:</b> Mehr Bewertungen mit evtl. falschen Telefonnummern? Oder weniger Bewertungen mit überprüfter Nummer? <br><br>
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="d-flex justify-center"
            :class="{'pa-0 px-0': $vuetify.breakpoint.smAndDown}"
          >
            <div
              class="d-flex flex-column"
              :style="{'max-width':$vuetify.breakpoint.smAndDown ? '100%' : '70%'}"
            >
              <div class="d-flex">
                <span>Rufnummer Verifizierung aktivieren: </span>
                <v-switch
                  v-model="isPhoneVerificationEnabled"
                  class="ma-0 mx-2"
                  dense
                  hide-details
                  @change="updatePhoneVerificationEnabled"
                />
              </div>
              <v-img
                :src="phoneVerificationSettingSrc"
                alt="phone verification image"
                contain
                max-width="100%"
              />
            </div>
          </v-col>
        </v-row>
      </Feature>
      <v-alert
        icon="mdi-lightbulb-on-20"
        prominent
        text
        type="info"
        :class="{'ma-0 mt-4': $vuetify.breakpoint.smAndDown}"
      >
        <p class="ma-0 font-weight-bold">
          Hinweis:
        </p>
        <span>Änderungen bei der Aktivierung oder Deaktivierung benötigen ein wenig Zeit, um in Kraft zu treten.</span>
      </v-alert>
    </div>
  </SettingsCard>
</template>

<script>

import SettingsCard from '../../components/SettingsCard.vue'
import UPDATE_PHONE_VERIFICATION from './queries/UpdatePhoneVerification.gql'
import UPDATE_PLOT_VALUATION from './queries/UpdatePlotValuation.gql'
import bus, { eventNames } from '@/lib/eventBus'
import { isExpertUser } from '@/lib/productTypes'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'

export default {
  components: {
    SettingsCard,
    Feature
  },
  props: {
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      plotValuationSettingSrc: 'img/settings-plot-valuation.png',
      phoneVerificationSettingSrc: 'img/settings-phone-verification.png',
      isPhoneVerificationEnabled: this.settings.company.enabledPhoneVerification.VALUATION,
      isPlotValuationInValuation: this.settings.company.plotValuation.showInPropertyValuation,
      featureNames
    }
  },
  computed: {
    isExpert () {
      return isExpertUser(this.$auth.user)
    },
    subtitle () {
      return this.$features.feature(featureNames.PLOT_VALUATION).isActive
        ? 'Grundstücksbewertung und Telefonnummer-Verifizierung'
        : 'Telefonnummer-Verifizierung'
    }
  },
  methods: {
    updatePhoneVerificationEnabled (value) {
      this.isPhoneVerificationEnabled = value
      this.saveSettings(this.savePhoneVerificationSetting)
    },
    updatePlotValuationInValuation (value) {
      this.isPlotValuationInValuation = value
      this.saveSettings(this.savePlotValuationSetting)
    },
    async savePhoneVerificationSetting () {
      await this.$apollo.mutate({
        mutation: UPDATE_PHONE_VERIFICATION,
        variables: {
          input: { enabled: this.isPhoneVerificationEnabled, companyId: this.$auth.user.companyId }
        }
      })
    },
    async savePlotValuationSetting () {
      await this.$apollo.mutate({
        mutation: UPDATE_PLOT_VALUATION,
        variables: {
          input: { showInPropertyValuation: this.isPlotValuationInValuation, companyId: this.$auth.user.companyId }
        }
      })
    },
    async saveSettings (save) {
      try {
        await save()
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    }
  }
}
</script>
