<template>
  <SettingsCard
    title="Vertagseinstellungen"
    subtitle="Einstellungen rund um den Vertrag und Bezahlung"
    icon="mdi-file-sign"
    :is-default-open="true"
  >
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          Rechnungssdaten, Zahlungsart & Kündigung
        </div>
        <div class="grey--text">
          Hier können Sie Ihr aktuelles Abonnement, Rechnungen, Rechnungssdaten sowie Zahlungsart einsehen und ändern. Außerdem können Sie Ihren Vertrag kündigen.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center"
      >
        <a
          :href="billingSelfServicePortalUrl"
          target="_blank"
        >
          <v-btn color="primary">Vertragseinstellungen öffnen</v-btn>
        </a>
      </v-col>
    </v-row>
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          Reaktivierung & Upgrade
        </div>
        <div class="grey--text">
          Wenn Sie sich umentscheiden und wieder Light abonieren oder auf ein anderes Paket wechseln wollen, kontaktieren Sie unseren Support.
        </div>
        <!-- <a :href="`mailto:${defaultContact.email}`">
          <v-icon class="mr-2">
            mdi-email
          </v-icon>
          <span>{{ defaultContact.email }}</span>
        </a> -->
      </v-col>
    </v-row>
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import SELF_SERVICE_PORTAL_URL from './queries/SelfServicePortalUrl.gql'
import featureMixin from '@/mixins/feature'

export default {
  components: { SettingsCard },
  mixins: [featureMixin],
  computed: {
    defaultContact () {
      return this.$features.feature(this.featureNames.SUPPORT_CONTACT).config
    }
  },
  apollo: {
    billingSelfServicePortalUrl: {
      query: SELF_SERVICE_PORTAL_URL
    }
  }
}
</script>
