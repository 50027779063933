<template>
  <SettingsCard
    title="Domainverifizierung"
    subtitle="Domainverifizierung mit DKIM und Return-Path"
    icon="mdi-email-seal"
    :is-default-open="false"
  >
    <div
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Ihre E-Mail-Domain
              </span>
            </div>
            <div class="grey--text mb-2">
              Ihre E-Mail-Domain bezieht sich auf Ihren derzeit aktiven E-Mail Absender.
              Um eine andere Domain zu verifizieren, ändern Sie den E-Mail Absender.
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mr-12 d-flex align-center justify-space-between">
            <v-text-field
              class="font-weight-bold"
              label="Domain"
              hide-details
              readonly
              outlined
              auto-grow
              no-resize
              rows="1"
              :value="domain.domain"
            />
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="hoppermation.hasDKIMVerification"
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                DKIM (DomainKeys Identified Mail)
              </span>
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">Was ist DKIM?<br></span>
              DKIM steht für DomainKeys Identified Mail und ist eine Methode der E-Mail-Authentifizierung.
              Sie stellt sicher, dass Ihre E-Mails nur noch mit erheblichem Aufwand gefälscht werden können und bietet damit erhöhte Sicherheit vor Spamming und Phishing.
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">Wie funktioniert DKIM?<br></span>
              DKIM fügt allen von uns in Ihrem Namen versendeten E-Mails eine spezielle Signatur hinzu, die Ihrer Domain zugeordnet ist.
              Der empfangende E-Mail Server ist damit in der Lage festzustellen, dass es sich tatsächlich um eine E-Mail von Ihnen handelt.
              Der E-Mailverkehr ist sicherer und die Wahrscheinlichkeit, dass Ihre E-Mails im Spamordner des Empfängers landen, wesentlich geringer.
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mb-4 d-flex align-center justify-space-between">
            <div
              v-if="domain.isDKIMVerified"
              class="font-weight-bold"
            >
              <v-icon
                color="success"
                class="pr-4"
              >
                mdi-check-decagram
              </v-icon>
              <span>verifiziert</span>
            </div>

            <div
              v-else
              class="font-weight-bold"
            >
              <v-icon
                color="error"
                class="pr-4"
              >
                mdi-alert-decagram
              </v-icon>
              <span>nicht verifiziert</span>
            </div>
            <v-btn
              v-if="!domain.isDKIMVerified"
              outlined
              color="primary"
              class="px-2 option-btn"
              :loading="isLoading"
              :disabled="isLoading"
              @click="verifyDKIM"
            >
              Verifizieren
            </v-btn>
          </div>
          <div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                label="Hostname"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                rows="1"
                :value="domain.dkimHost"
              />

              <CopyToClipboardButton :value="domain.dkimHost" />
            </div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                label="TXT Eintrag"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                :value="domain.dkimText"
              />
              <CopyToClipboardButton :value="domain.dkimText" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="hoppermation.hasReturnPathVerification"
      class="pa-4"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Return-Path (Rücksendepfad)
              </span>
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">Was ist der Return-Path?<br></span>
              Der Return-Path wird im E-Mail-Header als separate Antwort-Adresse für unzustellbare E-Mails angegeben.
            </div>
            <div class="grey--text mb-2">
              <span class="font-weight-bold">Wie funktioniert der Return-Path?<br></span>
              Durch die Verwendung eines Return-Path kann die Glaubwürdigkeit gegenüber E-Mail-Anbietern verbessert werden.
              Es kann in deren Filterprozesse einbezogen werden, um die Zustellbarkeit zu steigern.
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column px-0 px-md-2"
        >
          <div class="mb-4 d-flex align-center justify-space-between">
            <div
              v-if="domain.isReturnPathDomainVerified"
              class="font-weight-bold"
            >
              <v-icon
                color="success"
                class="pr-4"
              >
                mdi-check-decagram
              </v-icon>
              <span>verifiziert</span>
            </div>

            <div
              v-else
              class="font-weight-bold"
            >
              <v-icon
                color="error"
                class="pr-4"
              >
                mdi-alert-decagram
              </v-icon>
              <span>nicht verifiziert</span>
            </div>
            <v-btn
              v-if="!domain.isReturnPathDomainVerified"
              outlined
              color="primary"
              class="px-2 option-btn"
              :disabled="isLoading"
              :loading="isLoading"
              @click="verifyReturnPath"
            >
              Verifizieren
            </v-btn>
          </div>
          <div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                label="Hostname"
                hide-details
                readonly
                outlined
                auto-grow
                no-resize
                rows="1"
                :value="domain.returnPathDomain"
              />
              <CopyToClipboardButton :value="domain.returnPathDomain" />
            </div>
            <div class="d-flex">
              <v-textarea
                class="mb-4"
                label="CNAME Eintrag"
                hide-details
                readonly
                outlined
                :rows="1"
                auto-grow
                no-resize
                :value="domain.returnPathDomainCname"
              />
              <CopyToClipboardButton :value="domain.returnPathDomainCname" />
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-alert
      v-if="hoppermation.hasReturnPathVerification || hoppermation.hasDKIMVerification"
      icon="mdi-lightbulb-on-20"
      prominent
      text
      type="info"
      class="ma-4 mt-0"
    >
      <p class="ma-0 font-weight-bold">
        Wie kann ich die Domainverifizierung nutzen?
      </p>
      <ol>
        <li>
          In den DNS-Einstellungen des Hosters Ihrer Internetadresse (Domain) können diese Einträge hinzugefügt werden.<br>
          <a
            href="https://postmarkapp.com/support/article/1090-resources-for-adding-dkim-and-return-path-records-to-dns-for-common-hosts-and-dns-providers"
            target="_blank"
            rel="noopener"
          >Anleitung einiger gängiger Hoster</a>
        </li>
        <li>
          Danach können Sie hier die Einstellungen verifizieren.
        </li>
      </ol>
    </v-alert>
    <VerifyDomainDialog
      :dialog="verifyDialog"
      @close="verifyDialog = false"
    />
  </SettingsCard>
</template>

<script>

import SettingsCard from '@/components/SettingsCard.vue'
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue'
import GET_DOMAIN from '@/queries/GetDomain.gql'
import VERIFY_DOMAIN from './queries/VerifyDomain.gql'
import bus, { eventNames } from '@/lib/eventBus'
import VerifyDomainDialog from './VerifyDomainDialog.vue'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import { clipboard } from '@/lib/clipboard'

export default {
  components: { SettingsCard, VerifyDomainDialog, CopyToClipboardButton },
  mixins: [featureMixin],
  data () {
    return {
      isLoading: false,
      verifyDialog: false,
      domain: {}
    }
  },
  computed: {
    hoppermation () {
      const {
        config: {
          hasDKIMVerification,
          hasReturnPathVerification
        } = { config: {} }
      } = this.getFeature(featureNames.HOPPERMATION)
      return {
        hasDKIMVerification,
        hasReturnPathVerification
      }
    }
  },
  methods: {
    async verifyDKIM () {
      await this.verifyDomain()
      if (!this.domain.isDKIMVerified) {
        this.verifyDialog = true
      }
    },
    async verifyReturnPath () {
      await this.verifyDomain()
      if (!this.domain?.isReturnPathDomainVerified) {
        this.verifyDialog = true
      }
    },
    async copyToClipboard  (value) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'In die Zwischenablage kopiert.' })

      return await clipboard(value)
    },
    async verifyDomain () {
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: VERIFY_DOMAIN,
          variables: {
            companyId: this.$auth.user.companyId
          },
          update: (store, { data: { domain } }) => {
            store.writeQuery({ query: GET_DOMAIN, data: { domain }, variables: { companyId: this.$auth.user.companyId } })
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Status aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    domain: {
      query: GET_DOMAIN,
      variables () {
        return { companyId: this.$auth.user.companyId }
      }
    }
  }
}
</script>
