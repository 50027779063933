<template>
  <div>
    <v-btn
      color="primary"
      @click="show = true"
    >
      <v-icon>
        mdi-plus
      </v-icon>
      Neuen Absender Erstellen
    </v-btn>
    <v-dialog
      v-model="show"
      width="1000"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Neue Absender-Adresse erstellen</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="show = false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-4 text-body-1">
          <v-row>
            <v-col
              cols="12"
              md="7"
            >
              <p>
                Bevor die neue Absender-Adresse aktiviert werden kann, muss sie verifiziert werden.
                Das bedeutet, dass Sie nachweisen müssen, dass Sie der Besitzer der E-Mail-Adresse sind.
              </p>
              <p>
                Hierfür wird Ihnen eine Bestätigungs-E-Mail von unserem E-Mail-Dienstleister <strong>Postmark</strong> zugesendet.<br>
                Öffnen Sie die E-Mail von "Postmark-Support" und klicken Sie darin auf den blauen Button "Confirm Sender Signature",
                um die Aktivierung abzuschließen.
              </p>
              <div class="pt-2">
                <v-form
                  v-model="valid"
                  @submit.prevent="createSender"
                >
                  <v-text-field
                    v-model="name"
                    label="Name"
                    outlined
                    :rules="[rules.required]"
                  />
                  <v-text-field
                    v-model="fromEmail"
                    autofocus
                    :rules="[rules.email, rules.required]"
                    outlined
                    label="Absender-Adresse"
                    required
                  />
                  <v-text-field
                    v-model="replyToEmail"
                    :rules="[rules.email]"
                    outlined
                    label="Antwort-Adresse"
                  />
                </v-form>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="5"
            >
              <v-img src="https://static.bottimmo.com/images/assets/app-branding/email-verification-mail.png?h=600" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            @click="show = false"
          >
            Abbrechen
          </v-btn>
          <v-spacer />

          <v-btn
            color="success"
            :disabled="isLoading"
            :loading="isLoading"
            text
            @click="createSender"
          >
            Erstellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import CREATE_HOPPERMATION_SENDER from './queries/CreateHoppermationSender.gql'
import eventBus, { eventNames } from '@/lib/eventBus'
import { email, required } from '@/lib/validation'

export default {
  props: {
    companyName: { type: String, default: '' }
  },
  data () {
    return {
      rules: { email, required },
      show: false,
      isLoading: false,
      valid: false,
      name: this.companyName,
      fromEmail: null,
      replyToEmail: null
    }
  },
  watch: {
    companyName (companyName) {
      this.name = companyName
    },
    show (show) {
      if (show) {
        this.$emit('opened')
      }
    }
  },
  methods: {
    async createSender () {
      if (!this.valid) {
        return
      }
      try {
        this.isLoading = true
        await this.$apollo.mutate({
          mutation: CREATE_HOPPERMATION_SENDER,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              name: this.name,
              fromEmail: this.fromEmail,
              replyToEmail: this.replyToEmail
            }
          },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: {
              companyId: this.$auth.user.companyId
            }
          }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Neue Absender-Adresse erstellt' })
        this.show = false
        this.$emit('created')
      } catch (e) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Erstellen' })
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
