<template>
  <SettingsCard
    title="Bilder"
    subtitle="Bilder helfen, Ihre E-Mails, Materialien und Landingpages noch individueller und persönlicher zu gestalten."
    icon="mdi-camera"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Ihr Profilbild/ Ihr Portrait
              </span>
            </div>
            <span class="grey--text">
              Wenn vorhanden, wird dieses Bild im Footer aller E-Mails angezeigt, die wir in Ihrem Namen an Ihre Kontakte versenden, sowie auf den Landingpages. Ihr Kontakte können so wesentlich persönlicher angesprochen werden.
              Dies steigert die Antwortrate. Gleichzeitig wird die Abmelderate reduziert.<br>
              So muss das Bild beschaffen sein:
            </span>
            <ul class="grey--text">
              <li>
                Größe: Mindestens 1200x1200 Pixel
              </li>
              <li>
                Quadratisches Format
              </li>
              <li>
                Dateiformat: .jpg/.jpeg
              </li>
            </ul>
            <span class="grey--text">
              Hinweis: Sie können das Foto jederzeit durch Hochladen eines anderen Fotos ersetzen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center px-0 px-md-2 mt-4"
        >
          <CropImageUpload
            :min-height="1200"
            :min-width="1200"
            :ratio="1"
            accept="image/jpeg"
            :save="(image) => updateAmbassadorImage(image, 'image')"
          />
          <v-img
            :src="company?.ambassador?.image?.url"
            height="200px"
            contain
            class="mt-4"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="isLogoEditable"
        class="mt-8 mb-2"
      >
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Ihr Firmenlogo
              </span>
            </div>
            <span class="grey--text">
              Wenn vorhanden, wird Ihr Logo im Footer aller E-Mails angezeigt, die wir in Ihrem Namen an Ihre Kontakte versenden. Ihr Kontakte können so wesentlich persönlicher angesprochen werden.
              Dies steigert die Antwortrate.<br>
              So muss das Bild beschaffen sein:
            </span>
            <ul class="grey--text">
              <li>
                Größe: Mindestens 1200x1200 Pixel
              </li>
              <li>
                Transparenter Hintergrund
              </li>
              <li>
                Dateiformat: .png
              </li>
            </ul>
            <span class="grey--text">
              Hinweis: Sie können das Logo jederzeit durch Hochladen eines anderen Logos ersetzen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center px-0 px-md-2 mt-4"
        >
          <CropImageUpload
            :min-height="100"
            :min-width="1200"
            :min-opacity="0.01"
            accept="image/png"
            :save="(image) => updateImage(image, 'logo')"
          />
          <v-img
            :src="company?.images?.logo?.url"
            height="200px"
            contain
            class="mt-4"
          />
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>
import SettingsCard from '../../components/SettingsCard.vue'
import bus, { eventNames } from '@/lib/eventBus'
import CropImageUpload from '@/components/CropImageUpload.vue'
import UPDATE_COMPANY_IMAGES from './queries/UpdateCompanyImages.gql'
import UPDATE_COMPANY_AMBASSADOR_PORTRAIT from './queries/UpdateCompanyAmbassadorPortrait.gql'
import COMPANY from './queries/CompanyData.gql'

export default {
  components: {
    SettingsCard,
    CropImageUpload
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isLogoEditable () {
      return true
    }
  },
  methods: {
    async updateImage (file, type) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_IMAGES,
          variables: {
            input: {
              companyId: this.company.id,
              images: [
                { type, image: { id: this.company.images[type]?.id, file } }
              ]
            }
          },
          refetchQueries: [{ query: COMPANY, variables: { id: this.company.id } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    },
    async updateAmbassadorImage (file, type) {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_PORTRAIT,
          variables: {
            input: {
              companyId: this.company.id,
              [type]: {
                id: this.company.ambassador.image?.id,
                file
              }
            }
          },
          refetchQueries: [{ query: COMPANY, variables: { id: this.company.id } }]
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    }
  }
}
</script>
