<template>
  <div>
    <BaseHeader
      title="Einstellungen"
      :style-variant="6"
      icon="mdi-cog"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          In diesem Bereich finden Sie Einstellungen für Benachrichtigungen, Köderverhalten und mehr.
          Individualisieren Sie beispielsweise Ihre Landingpage-Navigation, oder aktivieren Sie den postalischen Versand eines Dokuments.
        </p>
      </template>
    </BaseHeader>
    <v-card
      outlined
      class="px-2 pt-2 px-md-4"
    >
      <div>
        <v-tabs
          v-model="section"
        >
          <v-tab v-if="showCompanySettings">
            Unternehmen
          </v-tab>
          <v-tab>Persönlich</v-tab>
          <v-tab v-if="showFunctionalSettings">
            Funktionen
          </v-tab>
          <Feature :feature-slug="featureNames.HOPPERMATION">
            <v-tab>E-Mail</v-tab>
          </Feature>
          <Feature :feature-slug="featureNames.BILLWERK">
            <v-tab>Vertrag</v-tab>
          </Feature>
        </v-tabs>

        <v-divider />

        <v-tabs-items
          v-model="section"
          class="pt-4"
        >
          <v-tab-item v-if="showCompanySettings">
            <company-settings />
          </v-tab-item>
          <v-tab-item>
            <AccountSettings />
          </v-tab-item>
          <v-tab-item v-if="showFunctionalSettings">
            <FunctionalSettings />
          </v-tab-item>
          <Feature :feature-slug="featureNames.HOPPERMATION">
            <v-tab-item>
              <email-settings />
            </v-tab-item>
          </Feature>
          <Feature :feature-slug="featureNames.BILLWERK">
            <v-tab-item>
              <contract-settings />
            </v-tab-item>
          </Feature>
        </v-tabs-items>
      </div>
    </v-card>
  </div>
</template>

<script>
import FunctionalSettings from '../functionalSettings'
import AccountSettings from '../accountSettings'
import CompanySettings from '../companySettings'
import EmailSettings from './emailSettings'
import ContractSettings from './contractSettings'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'
import { isLightUser } from '@/lib/productTypes'
import featureMixin from '@/mixins/feature'

export default {
  components: {
    FunctionalSettings,
    AccountSettings,
    CompanySettings,
    EmailSettings,
    ContractSettings,
    BaseHeader,
    HeaderDesign
  },
  mixins: [featureMixin],
  data () {
    return {
      section: null
    }
  },
  computed: {
    showCompanySettings () {
      return !isLightUser(this.$auth.user)
    },
    sections () {
      return [
        'company',
        'account',
        ...(this.showFunctionalSettings ? ['features'] : []),
        ...(this.$features.feature(this.featureNames.HOPPERMATION)?.isActive ? ['email'] : []),
        ...(this.$features.feature(this.featureNames.BILLWERK)?.isActive ? ['contract'] : [])
      ]
    },
    showFunctionalSettings () {
      return !isLightUser(this.$auth.user)
    }
  },
  watch: {
    section (section) {
      if (this.sections[section] !== this.$route.params.section) {
        this.$router.replace('/settings/' + this.sections[section] || this.sections[0])
      }
    }
  },
  created () {
    const index = this.sections.indexOf(this.$route.params.section)
    this.section = index > 0 ? index : 0
  }
}
</script>
