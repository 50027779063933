<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="px-0 ml-2"
        v-bind="attrs"
        icon
        v-on="on"
        @click="copyToClipboard"
      >
        <v-icon>
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>
    <span>Kopieren</span>
  </v-tooltip>
</template>

<script>

import bus, { eventNames } from '@/lib/eventBus'
import { clipboard } from '@/lib/clipboard'

export default {
  props: {
    value: { type: String, default: '' }
  },
  methods: {
    async copyToClipboard  () {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'In die Zwischenablage kopiert.' })

      return await clipboard(this.value)
    }
  }
}
</script>
