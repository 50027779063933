<template>
  <v-dialog
    v-model="dialog"
    width="550"
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Wie kann ich die Domainverifizierung nutzen?</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="$emit('close')"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-4 text-body-1">
        <div class="pb-4">
          Ihre Domain ist noch nicht vollständig verifiziert.
        </div>
        <div class="pb-4">
          In den DNS-Einstellungen des Hosters Ihrer Internetadresse (Domain) müssen diese Einträge hinzugefügt werden.
        </div>
        <a
          href="https://postmarkapp.com/support/article/1090-resources-for-adding-dkim-and-return-path-records-to-dns-for-common-hosts-and-dns-providers"
          target="_blank"
          rel="noopener"
        >Hier finden Sie eine Anleitung gängiger Hoster</a>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="success"
          text
          @click="$emit('close')"
        >
          Schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false }
  }
}
</script>
