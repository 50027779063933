<template>
  <SettingsCard
    title="Landingpage-Navigation"
    subtitle="Steuern Sie, welche Funktionen in der Navigation und auf Ihrer Landingpage-Übersicht angezeigt werden sollen."
    icon="mdi-menu"
    :is-default-open="false"
  >
    <div class="px-4 pt-4">
      <v-row
        v-for="(category,index) in categories"
        :key="index"
      >
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              {{ category.title }}<br>
            </span>
            <span class="grey--text">
              Sie entscheiden, welche  {{ category.title }} in der Landingpage-Navigation zu sehen sind, indem Sie die einzelnen Elemente hier an- oder abschalten.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-expansion-panels flat>
            <v-expansion-panel class="expansion-panel-border">
              <v-expansion-panel-header>
                <span>
                  Liste aller {{ category.title }}
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item,i) in category.items"
                  :key="i"
                  class="py-1"
                >
                  <v-switch
                    v-if="!item.disabled"
                    v-model="activePages"
                    :value="item.slug"
                    :label="`${item.name}`"
                    dense
                    :disabled="item.disabled"
                    :loading="isLoadingSlugs[item.slug]"
                    @click.native.capture="changeStatus($event, item.slug)"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
    <v-alert
      icon="mdi-lightbulb-on-20"
      prominent
      text
      type="info"
      class="mt-4"
    >
      <p class="ma-0 font-weight-bold">
        Hinweis:
      </p>
      <span>Änderungen bei der Aktivierung oder Deaktivierung benötigen ein wenig Zeit, um in Kraft zu treten.</span>
    </v-alert>
    <v-dialog
      v-model="isUpsellingDialogVisible"
      width="500"
    >
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ title }} aktivieren</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="isUpsellingDialogVisible=false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="mt-5">
          <p>
            Sie können {{ content }}: <b>{{ selectedItem?.name }}</b> jederzeit Ihrem Paket hinzufügen.
            Weitere Informationen dazu finden Sie hier:
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="routeToUpselling()"
          >
            Landingpages &amp; Köder öffnen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SettingsCard>
</template>

<script>
import currencyMixin from '@/mixins/currency'
import SettingsCard from '../../components/SettingsCard.vue'
import bus, { eventNames } from '@/lib/eventBus'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'

export default {
  components: { SettingsCard },
  mixins: [currencyMixin, featureMixin, brandingMixin],
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    guides: {
      type: Array,
      default: () => ([])
    },
    checklists: {
      type: Array,
      default: () => ([])
    },
    tools: {
      type: Array,
      default: () => ([])
    },
    refetchSettings: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      selectedItem: null,
      isUpsellingDialogVisible: false,
      activePages: this.getActivePages(),
      isLoadingSlugs: {},
      loaderTimeout: null,
      categories: {
        tools: {
          title: 'Tools',
          items: this.tools
        },
        guides: {
          title: 'Ratgeber',
          items: this.guides
        },
        checklists: {
          title: 'Checklisten',
          items: this.checklists
        }
      }
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': this.$vuetify.theme.themes.light.primary
      }
    },
    route () {
      return {
        guide: 'guides',
        checklist: 'checklists'
      }[this.selectedItem?.type.toLowerCase()] || 'tools'
    },
    title () {
      return {
        guides: 'Diesen Ratgeber',
        checklists: 'Diese Checkliste',
        tools: 'Dieses Tool'
      }[this.route]
    },
    content () {
      return {
        guides: 'den Ratgeber',
        checklists: 'die Checkliste',
        tools: 'das Tool'
      }[this.route]
    }
  },
  unmounted () {
    clearTimeout(this.loaderTimeout)
  },
  created () {
    this.$watch(vm => [vm.tools, vm.guides, vm.checklists].join(), lps => {
      this.activePages = this.getActivePages()
      this.categories.tools.items = this.tools
      this.categories.checklists.items = this.checklists
      this.categories.guides.items = this.guides
    })
    bus.$on(eventNames.CHANGE_FEATURE_CONFIG, (event) => {
      if (event.slug !== featureNames.LANDINGPAGE_NAVIGATION) return
      if (event.error) bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      this.activePages = this.getActivePages()
    })
  },
  methods: {
    getActivePages () {
      return [...this.tools, ...this.guides, ...this.checklists]
        .map(({ slug }) => slug)
        .filter(slug => this.$features._featuresBySlug['landingpage-navigation'].config[slug])
    },
    showUpsellingDialog (tool) {
      if (!tool.disabled) return
      this.isUpsellingDialogVisible = true
      this.selectedItem = tool
    },
    routeToUpselling () {
      this.$router.push('/landingpages/#' + this.route)
    },
    finishLoading (milliseconds) {
      setTimeout(() => { this.loading = false }, milliseconds)
    },
    changeStatus (event, slug) {
      this.$set(this.isLoadingSlugs, slug, true)
      this.loaderTimeout = setTimeout(() => {
        this.saveSettings(slug)
      }, 2000)
    },
    async saveSettings (changedSlug) {
      await this.$features.setCompanyFeature(
        bus,
        {
          companyId: this.$auth.user.companyId,
          slug: featureNames.LANDINGPAGE_NAVIGATION,
          config: [...this.tools, ...this.guides, ...this.checklists].reduce((obj, lp) => {
            obj[lp.slug] = this.activePages.includes(lp.slug)
            return obj
          }, {})
        }
      )
      this.$set(this.isLoadingSlugs, changedSlug, false)
    }
  }
}
</script>
<style scoped>
.design-wrapper {
  position: relative;
  height: 1px;
}

.design-elements{
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.overlay{
  z-index: 2;
  position: relative;
}

.switch {
  min-height: 40px;
}
.upselling-container{
  position:relative;
  cursor: pointer;
  max-width: 400px;
  overflow:hidden;
}
.background-image{
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 30px;
}
</style>
