<template>
  <v-container
    v-if="settings && guides"
    class="layout-container pa-0"
  >
    <ValuationSettings
      v-if="showValuationSettings"
      :settings="settings"
      :refetch-settings="refetchCompanySettings"
    />

    <v-divider
      v-if="showValuationSettings"
      class="my-4"
    />

    <PostalShippingSettings
      :settings="settings"
      :guides="activeGuides"
      :refetch-settings="refetchCompanySettings"
      :is-default-open="!showValuationSettings && !showNavigationSettings"
    />

    <v-divider
      v-if="showNavigationSettings"
      class="my-4"
    />

    <NavigationSettings
      v-if="showNavigationSettings"
      :settings="settings"
      :tools="tools"
      :guides="guides"
      :checklists="checklists"
      :refetch-settings="refetchCompanySettings"
    />
  </v-container>
</template>

<script>
import ValuationSettings from './ValuationSettings.vue'
import NavigationSettings from './NavigationSettings.vue'
import SETTINGS from './queries/Settings.gql'
import LANDINGPAGES from './queries/Landingpages.gql'
import LANDINGPAGES_TEMPLATES from '@/queries/LandingpageTemplatesForCompany.gql'
import PostalShippingSettings from './PostalShippingSettings.vue'
import featureMixin from '@/mixins/feature'
import featureNames from '@/lib/featureNames'
import { TOOLS } from '@/lib/tools'

export default {
  components: {
    ValuationSettings,
    PostalShippingSettings,
    NavigationSettings
  },
  mixins: [featureMixin],
  computed: {
    showValuationSettings () {
      const {
        isActive: isValuationActive,
        config: { isPhoneNumberVerificationEnabled, canChangePhoneNumberVerification } = {}
      } = this.getFeature(featureNames.VALUATION)
      return this.isFeatureActive(featureNames.PLOT_VALUATION) || (isValuationActive && isPhoneNumberVerificationEnabled && canChangePhoneNumberVerification)
    },

    showNavigationSettings () {
      return this.isFeatureActive(featureNames.LANDINGPAGE_NAVIGATION)
    },

    landingpages () {
      if (!this.company.landingpages || !this.landingpageTemplates) return []
      const mappedLps = this.landingpageTemplates.map(lp => ({
        ...lp,
        disabled: true
      }))
      return [...this.company.landingpages, ...mappedLps].sort((a, b) => { return a.slug > b.slug ? 1 : -1 })
    },
    tools () {
      return this.landingpages
        .map(lp => ({ ...lp, ...TOOLS.find(tool => tool.type === lp.type) }))
        .filter(lp => lp.order)
        .sort((a, b) => a.order - b.order)
    },
    guides () {
      return this.landingpages.filter(({ type }) => type === 'GUIDE')
    },
    checklists () {
      return this.landingpages.filter(({ type }) => type === 'CHECKLIST')
    },
    activeGuides () {
      return this.guides.filter(({ status }) => status === 'ACTIVE')
    }
  },
  created () {
    this.featureNames = featureNames
  },
  methods: {
    refetchCompanySettings () {
      return this.$apollo.queries.settings.refetch()
    }
  },
  apollo: {
    settings: {
      query: SETTINGS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return { id: this.$auth.user.companyId }
      }
    },
    landingpageTemplates: {
      query: LANDINGPAGES_TEMPLATES,
      variables () {
        return {
          input: {
            companyId: this.$auth.user.companyId,
            onlyDefault: true
          }
        }
      }
    }
  }
}
</script>
