<template>
  <div class="w-full">
    <v-form
      ref="linksForm"
      v-model="isFormValid"
    >
      <v-row class="ma-0 pa-0">
        <div
          v-if="!isFieldVisible('about')"
          class="mb-2 pl-2 info-text"
        >
          Ihre Website-Adresse für den richtigen Link auf Ihre neuen Landingpages:
        </div>
        <v-text-field
          ref="website"
          v-model="linksData.website"
          outlined
          type="url"
          label="URL zu Ihrer Website"
          placeholder="https://www.max-makler.de"
          :rules="[rules.url, rules.required]"
          :loading="isLoading.website"
          @input="(val) => update('website', val)"
        />
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-text-field
          v-if="isFieldVisible('about')"
          ref="about"
          v-model="linksData.about"
          type="url"
          outlined
          label="URL zu Ihrer Über uns / Teamseite"
          placeholder="https://www.max-makler.de/team"
          :rules="[rules.url, rules.required]"
          :loading="isLoading.about"
          @input="(val) => update('about', val)"
        />
      </v-row>
      <v-row class="ma-0 pa-0">
        <div
          v-if="!isFieldVisible('about')"
          class="mb-2 pl-2 info-text"
        >
          Die Adresse Ihres Impressums, damit Sie rechtlich auf der sicheren Seite sind:
        </div>
        <v-text-field
          ref="imprint"
          v-model="linksData.imprint"
          type="url"
          outlined
          label="URL zu Ihrem Impressum"
          placeholder="https://www.max-makler.de/impressum"
          :rules="[rules.url, rules.required]"
          :loading="isLoading.imprint"
          @input="(val) => update('imprint', val)"
        />
      </v-row>
      <v-row class="ma-0 pa-0">
        <div
          v-if="!isFieldVisible('about')"
          class="mb-2 pl-2 info-text"
        >
          Die Adresse Ihrer Datenschutzerklärung, damit alles DSGVO konform ist:
        </div>
        <v-text-field
          ref="privacy"
          v-model="linksData.privacy"
          type="url"
          outlined
          label="URL zu Ihrer Datenschutzerklärung"
          placeholder="https://www.max-makler.de/datenschutz"
          :rules="[rules.url, rules.required]"
          :loading="isLoading.privacy"
          @input="(val) => update('privacy', val)"
        />
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { url, required } from '@/lib/validation'

export default {
  props: {
    links: {
      type: Object,
      default: () => ({})
    },
    hiddenFieldNames: {
      type: Array,
      default: () => []
    },
    hasDebounceTimer: {
      type: Boolean,
      default: true
    },
    triggerValidation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      linksData: { ...this.links },
      debounceTimeout: null,
      spinnerTimeout: null,
      isLoading: {
        website: false,
        imprint: false,
        privacy: false,
        about: false
      },
      isFormValid: false
    }
  },

  watch: {
    links () {
      this.linksData = { ...this.links }
    },
    async triggerValidation () {
      if (this.triggerValidation) {
        const isFormValid = await this.$refs.linksForm.validate()
        if (isFormValid) {
          this.$emit('validated')
        }
        this.$emit('resetTriggerValidation')
      }
    }
  },
  created () {
    this.rules = { url, required }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  },

  methods: {
    isFieldVisible (fieldName) {
      return !this.hiddenFieldNames.includes(fieldName)
    },

    async update (name, value) {
      this.linksData[name] = value
      if (!this.hasDebounceTimer) {
        this.$emit('updateSettings', { ...this.linksData })
      } else {
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.isLoading[name] = true
          this.spinnerTimeout = setTimeout(async () => {
            const isFormValid = await this.$refs.linksForm.validate()
            if (isFormValid) {
              this.$emit('updateSettings', { ...this.linksData })
            }
            this.isLoading[name] = false
          }, 1000)
        }, 2000)
      }
    }
  }
}
</script>

<style scoped>
.info-text {
  font-size: 12px;
  text-align: left;
  background-color: none !important;
}
</style>
