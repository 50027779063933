<template>
  <SettingsCard
    v-if="user.notificationSettings"
    title="Benachrichtigungen"
    subtitle="Legen Sie fest, wie Sie up-to-date bleiben wollen. Für Benachrichtigungen via SMS benötigen wir Ihre Mobiltelefonnummer:"
    icon="mdi-bell-ring"
    :is-default-open="true"
  >
    <div class="pa-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              Kontaktnummer<br>
            </span>
            <span class="grey--text">
              Telefonnummer, auf welcher Sie die Benachrichtigungen erhalten möchten.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <v-form
            ref="phoneForm"
            v-model="isFormValid"
            @submit.prevent="submit()"
            @change="error = ''"
          >
            <div class="justify-center d-flex mb-sm-0">
              <PhoneField
                v-model="notificationPhone"
                :loading="isLoading.phone"
                update-on-input
              />
            </div>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <span class="font-weight-bold">
              Kontaktoptionen<br>
            </span>
            <span class="grey--text">
              Wählen Sie aus, wann und wie Sie benachrichtigt werden wollen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-end"
          :class="{'justify-center px-0': $vuetify.breakpoint.smAndDown}"
        >
          <div class="w-full">
            <v-row>
              <v-col
                cols="12"
                sm="8"
              />
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <div class="d-flex flex-column align-center">
                  <div class="subtitle-2">
                    MAIL
                  </div>
                  <v-icon>
                    mdi-email-outline
                  </v-icon>
                </div>
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <div class="d-flex flex-column align-center">
                  <div class="subtitle-2">
                    SMS
                  </div>
                  <v-icon>
                    mdi-phone-message
                  </v-icon>
                </div>
              </v-col>
            </v-row>

            <!-- New Contact -->
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                Ein neuer Kontakt wurde generiert.
                <v-tooltip
                  v-if="!user.notificationSettings.newConversion.email && !user.notificationSettings.newConversion.sms"
                  top
                  color="warning"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="warning"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Sie erhalten aktuell keine Benachrichtigungen</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <v-switch
                  v-model="user.notificationSettings.newConversion.email"
                  :loading="isLoading.newConversion.email"
                  @change="saveSettings('newConversion', 'email')"
                />
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <v-switch
                  v-model="user.notificationSettings.newConversion.sms"
                  :disabled="!hasValidPhone"
                  :loading="isLoading.newConversion.sms"
                  @change="saveSettings('newConversion', 'sms')"
                />
              </v-col>
            </v-row>

            <!-- Contact assigned -->
            <v-row>
              <v-col
                cols="12"
                sm="8"
              >
                Ihnen wurde ein Kontakt zugewiesen.
                <v-tooltip
                  v-if="!user.notificationSettings.leadAssigned.email && !user.notificationSettings.leadAssigned.sms"
                  top
                  color="warning"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="warning"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>Sie erhalten aktuell keine Benachrichtigungen</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <v-switch
                  v-model="user.notificationSettings.leadAssigned.email"
                  :loading="isLoading.leadAssigned.email"
                  @change="saveSettings('leadAssigned', 'email')"
                />
              </v-col>
              <v-col
                cols="6"
                sm="2"
                class="justify-center d-flex"
              >
                <v-switch
                  v-model="user.notificationSettings.leadAssigned.sms"
                  :disabled="!hasValidPhone"
                  :loading="isLoading.leadAssigned.sms"
                  @change="saveSettings('leadAssigned', 'sms')"
                />
              </v-col>
            </v-row>

            <!-- General -->
            <Feature
              v-slot="{feature}"
              :feature-slug="featureNames.NOTIFICATIONS"
            >
              <v-row v-if="feature.config.hasGeneralNotifications">
                <v-col
                  sm="8"
                  cols="12"
                  class="d-flex"
                >
                  <div>
                    Allgemeine Benachrichtungen

                    <div class="grey--text caption mt-n1">
                      Für Sie relevante Updates rund um {{ readablePlatformName }}
                    </div>
                  </div>
                  <v-tooltip
                    v-if="!user.notificationSettings.news.email"
                    top
                    color="warning"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="warning"
                        class="pl-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>Sie erhalten aktuell keine Benachrichtigungen</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="justify-center d-flex"
                >
                  <v-switch
                    v-model="user.notificationSettings.news.email"
                    :loading="isLoading.news.email"
                    @change="saveSettings('news', 'email')"
                  />
                </v-col>
              </v-row>
            </Feature>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-alert
      icon="mdi-lightbulb-on-20"
      prominent
      text
      type="info"
    >
      <p class="ma-0 font-weight-bold">
        Hinweis:
      </p>
      <ul style="list-style: disc;">
        <li>Sollten Sie die Benachrichtigungen nicht erhalten wie hier eingestellt, überprüfen Sie die Mobiltelefonnummer.</li>
        <li>Auf die SMS-Nachrichten von {{ readablePlatformName }} kann nicht geantwortet werden.</li>
      </ul>
    </v-alert>
  </SettingsCard>
</template>

<script>
import UPDATE_USER_NOTIFICATION_SETTINGS from './UpdateUserNotificationSettings.gql'
import UPDATE_USER_PHONE from './UpdateUserPhone.gql'
import bus, { eventNames } from '@/lib/eventBus'
import SettingsCard from '../../components/SettingsCard.vue'
import PhoneField from '@/components/PhoneField.vue'
import brandingMixing from '@/mixins/branding'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'

export default {
  components: { SettingsCard, PhoneField, Feature },
  mixins: [brandingMixing],
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    refetchUser: {
      type: Function,
      default: () => {}
    }
  },
  data: function () {
    return {
      notificationPhone: this.user.phone,
      isFormValid: false,
      spinnerTimeout: null,
      debounceTimeout: null,
      isLoading: {
        leadAssigned: {
          sms: false,
          mail: false
        },
        newConversion: {
          sms: false,
          mail: false
        },
        news: {
          mail: false
        },
        phone: false
      },
      featureNames
    }
  },
  computed: {
    isPhoneDirty () {
      return this.notificationPhone && this.user.phone !== this.notificationPhone
    },
    hasValidPhone () {
      return this.user.phone && this.user.phone.length > 0
    }
  },

  watch: {
    notificationPhone () {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(() => {
        this.isLoading.phone = true
        this.spinnerTimeout = setTimeout(() => {
          this.submit()
        }, 1000)
      }, 2000)
    }
  },
  methods: {
    async saveSettings (notification, source) {
      this.isLoading[notification][source] = true
      const enabled = this.user.notificationSettings[notification][source]

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_USER_NOTIFICATION_SETTINGS,
          variables: {
            input: { notification, source, enabled }
          }
        })

        this.spinnerTimeout = setTimeout(() => {
          this.refetchUser()
          this.isLoading[notification][source] = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
        }, 1000)
      } catch (e) {
        this.spinnerTimeout = setTimeout(() => {
          this.refetchUser()
          this.isLoading[notification][source] = false
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
        }, 1000)
      }
    },

    async submit () {
      if (this.$refs.phoneForm.validate()) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_USER_PHONE,
            variables: {
              input: { phone: this.notificationPhone }
            }
          })
          this.isLoading.phone = false
          this.refetchUser()
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Mobiltelefonnummer aktualisiert' })
        } catch (e) {
          if (e.graphQLErrors?.[0]?.message === 'INVALID_PHONE_ERROR') {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Mobiltelefonnummer ungültig' })
          } else {
            bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
          }
          this.refetchUser()
          this.isLoading.phone = false
        }
      }
    }
  },

  unmounted () {
    clearTimeout(this.spinnerTimeout)
    clearTimeout(this.debounceTimeout)
  }
}
</script>

<style scoped>

.v-input--selection-controls {
  margin-top: 0;
  max-height: 30px;
}
</style>
