<template>
  <SettingsCard
    title="Anschrift und Ansprechpartner Ihres Unternehmens"
    subtitle="Halten Sie Ihre Kontaktdaten stets vollständig und aktuell, um Ihren Kunden die Kontaktaufnahme so leicht wie möglich zu machen."
    icon="mdi-account-outline"
    :is-default-open="false"
  >
    <div class="pt-4 px-4">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Anschrift
              </span>
            </div>
            <span class="grey--text">
              Die Anschrift wird genutzt, um Ihre Landinpages zu personalisieren. In Kombination mit den Daten des Ansprechpartners (siehe unten) zeigen wir
              die Daten auf den Landingpages an, die wir für Sie bereitstellen. So haben es Kunden leicht, Sie bei Bedarf zu kontaktieren.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-center px-0 px-md-2 mt-4"
        >
          <AddressData
            v-bind="companyAddressData"
            @saveSettings="saveSettings"
          />
        </v-col>
      </v-row>
      <v-row class="mt-8">
        <v-col
          cols="12"
          sm="6"
          class="px-0 px-md-2"
        >
          <div>
            <div class="d-flex align-center">
              <span class="font-weight-bold">
                Ansprechpartner in Ihrem Unternehmen
              </span>
            </div>
            <span class="grey--text">
              Geben Sie hier den Hauptansprechpartner Ihres Unternehmens an. Diese Informationen verwenden wir unter Anderem auf den Landingpages, um potentiellen Kunden die Kontaktaufnahme so leicht wie möglich zu machen.
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex justify-center px-0 px-md-2 mt-4"
        >
          <AmbassadorData
            v-bind="ambassador"
            @saveSettings="saveAmbassadorSettings"
          />
        </v-col>
      </v-row>
    </div>
  </SettingsCard>
</template>

<script>

import SettingsCard from '../../components/SettingsCard.vue'
import AddressData from '@/components/forms/AddressData'
import AmbassadorData from '@/components/forms/AmbassadorData'
import UPDATE_COMPANY_NAME from './queries/UpdateCompanyName.gql'
import UPDATE_COMPANY_ADDRESS from './queries/UpdateCompanyAddress.gql'
import UPDATE_COMPANY_AMBASSADOR_DATA from './queries/UpdateCompanyAmbassadorData.gql'
import bus, { eventNames } from '@/lib/eventBus'

export default {
  components: {
    SettingsCard,
    AddressData,
    AmbassadorData
  },
  props: {
    company: {
      type: Object,
      default: () => ({})
    },
    settings: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    companyAddressData () {
      const { name, address } = this.company
      return { name, address }
    },
    ambassador () {
      const { ambassador } = this.company
      return { ambassador }
    }
  },
  methods: {
    async saveSettings (addressData) {
      const { __typename, name, ...address } = addressData
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_NAME,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              name
            }
          }
        })
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_ADDRESS,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              address
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    },
    async saveAmbassadorSettings (settings) {
      const { firstname, lastname, email, phone } = settings
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_COMPANY_AMBASSADOR_DATA,
          variables: {
            input: {
              companyId: this.$auth.user.companyId,
              ambassador: { firstname, lastname, email, phone }
            }
          }
        })
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Einstellungen aktualisiert' })
      } catch (e) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Problem beim Aktualisieren' })
      }
    }
  }
}
</script>
