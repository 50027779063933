<template>
  <div>
    <MidB
      class="banner-design-elements"
      width="150px"
      style="right: 50px; top:20px; opacity: 0.9"
    />

    <Leaf
      class="banner-design-elements"
      width="50px"
      style="right: 190px; top:30px; opacity: 0.3"
      stroke
      :stroke-width="1"
    />

    <Leaf
      class="banner-design-elements"
      width="80px"
      style="right: 220px; top:-20px; opacity: 0.6"
      stroke
      :stroke-width="1"
    />
  </div>
</template>

<script>
import Leaf from '@/components/design-elements/leaf.vue'
import MidB from '@/components/design-elements/midB.vue'

export default {
  components: {
    Leaf,
    MidB
  }
}
</script>
