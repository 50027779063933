<template>
  <div :class="`d-flex row align-center ${justify} flex-nowrap`">
    <div
      class="clickable"
      @click="() => switchValue = !switchValue"
    >
      <slot name="label" />
      <span v-if="label">
        {{ label }}
      </span>
    </div>

    <v-switch
      v-model="switchValue"
      v-bind="$attrs"
      class="my-0 ml-6 pt-0"
      hide-details
      :loading="loading"
    >
      <template
        v-slot:label
      >
        <span :class="switchValue ? 'mr-2' : 'mr-0'">
          {{ switchValue ? 'an' : 'aus' }}
        </span>
      </template>
    </v-switch>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean
    },
    justify: {
      type: String,
      default: 'justify-space-between'
    }
  },
  data () {
    return {
      switchValue: this.value,
      loading: false
    }
  },
  watch: {
    switchValue (value) {
      this.loading = 'primary'
      setTimeout(() => {
        this.$emit('input', value)
        this.loading = false
      }, 700)
    }
  }
}
</script>
<style>
.clickable {
  cursor: pointer;
}
</style>
