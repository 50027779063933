<template>
  <SettingsCard
    title="E-Mail Absender"
    subtitle="Passen Sie den Absender Ihrer Funnel E-Mails an."
    icon="mdi-email-fast-outline"
    :is-default-open="false"
  >
    <v-row class="ma-2">
      <v-col
        cols="12"
        md="6"
      >
        <div class="font-weight-bold">
          E-Mail-Absender
        </div>
        <div class="grey--text">
          Die E-Mail-Absender-Adresse ist die Adresse, von denen aus Ihre Funnel-E-Mails verschickt werden. Sie wird
          Ihren Kunden zusammen mit dem Absender-Namen angezeigt, wenn sie Ihre E-Mails empfangen.
          Dieser Name kann jederzeit frei gewählt werden. Außerdem können Sie die Antwort-Adresse anpassen,
          also die E-Mail-Adresse, an die Antworten auf Ihre E-Mail gesendet werden sollen.
        </div>
        <div class="font-weight-bold mt-4">
          Absender-Adresse ändern
        </div>
        <div class="grey--text">
          Die Absender-Adresse kann nicht geändert werden, wenn sie einmal erstellt wurde. Allerdings können Sie eine neue
          Absender-Adresse anlegen, falls Sie eine andere E-Mail-Adresse nutzen möchten.
        </div>
        <div class="font-weight-bold mt-4">
          Absender-Adresse verifizieren
        </div>
        <div class="grey--text">
          Bevor die neue Absender-Adresse aktiviert werden kann, muss sie verifiziert werden.
          Das bedeutet, dass Sie nachweisen müssen, dass Sie der Besitzer der E-Mail-Adresse sind.
          Hierfür wird Ihnen eine Bestätigungs-E-Mail zugesendet, in der Sie einen Bestätigungslink anklicken müssen.
        </div>
      </v-col>
      <v-col
        cols="12"
        offset="1"
        md="5"
      >
        <v-card
          outlined
          class="my-4 pa-2"
        >
          <v-img src="https://static.bottimmo.com/images/assets/app-branding/email-sender.png?h=800" />
        </v-card>
      </v-col>
    </v-row>
    <div class="ma-2 d-flex">
      <v-spacer />
      <CreateSenderDialog
        :company-name="company?.name"
        @opened="senderCreated = false"
        @created="senderCreated = true"
      />
    </div>
    <v-alert
      v-if="senderCreated"
      class="ma-4"
      border="left"
      colored-border
      type="info"
      elevation="2"
    >
      <h3 class="info--text">
        Bestätigungs-E-Mail erfolgreich versendet.
      </h3>
      <div>Schauen Sie jetzt in Ihr E-Mail Postfach und ggfs. in den Spam-Ordner!</div>
    </v-alert>
    <v-simple-table class="ma-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Name
            </th>
            <th class="text-left">
              Absender-Adresse
            </th>
            <th class="text-left">
              Antwort-Adresse
            </th>
            <th class="text-center">
              Verifiziert
            </th>
            <th class="text-center">
              Aktiv
            </th>
            <th class="text-center" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="sender of senders"
            :key="sender.id"
            :class="sender.isDefault ? 'font-weight-bold': 'grey--text'"
          >
            <td>{{ sender.name }}</td>
            <td>{{ sender.fromEmail }}</td>
            <td>{{ sender.replyToEmail }}</td>
            <td>
              <div class="d-flex justify-center">
                <v-icon
                  v-if="sender.isVerified"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <VerifySenderDialog
                  v-else
                  :sender="sender"
                />
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <v-icon
                  v-if="sender.isDefault"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <v-btn
                  v-else-if="sender.isVerified"
                  color="success"
                  outlined
                  :disabled="isLoading"
                  :loading="isLoading"
                  @click="setAsDefaultSender(sender.id)"
                >
                  Aktivieren
                </v-btn>
              </div>
            </td>
            <td>
              <div class="d-flex justify-center">
                <UpdateSenderDialog :sender="sender" />
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </SettingsCard>
</template>

<script>
import HOPPERMATION_SENDER from './queries/HoppermationSenders.gql'
import SET_DEFAULT_SENDER from './queries/SetDefaultSender.gql'
import COMPANY from './queries/Company.gql'
import SettingsCard from '@/components/SettingsCard.vue'
import CreateSenderDialog from './CreateSenderDialog.vue'
import featureMixin from '@/mixins/feature'
import VerifySenderDialog from './VerifySenderDialog.vue'
import eventBus, { eventNames } from '@/lib/eventBus'
import UpdateSenderDialog from './UpdateSenderDialog.vue'

export default {
  components: { SettingsCard, CreateSenderDialog, VerifySenderDialog, UpdateSenderDialog },
  mixins: [featureMixin],
  data () {
    return {
      senderCreated: false,
      isLoading: false,
      domain: {}
    }
  },
  computed: {
  },
  methods: {
    async setAsDefaultSender (senderId) {
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: SET_DEFAULT_SENDER,
          variables: { senderId },
          refetchQueries: [{
            query: HOPPERMATION_SENDER,
            variables: { companyId: this.$auth.user.companyId }
          }]
        })
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: 'Die Absender-Adresse wurde erfolgreich aktiviert.' })
      } catch (err) {
        eventBus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Fehler beim Speichern.' })
      } finally {
        this.isLoading = false
      }
    }
  },
  apollo: {
    senders: {
      query: HOPPERMATION_SENDER,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  }
}
</script>
