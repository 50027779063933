<template>
  <v-container class="layout-container pa-0">
    <ContractSettings />
  </v-container>
</template>

<script>
import ContractSettings from './ContractSettings.vue'

export default {
  components: {
    ContractSettings
  }
}
</script>
