<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 94.9 95.1"
    enable-background="new 0 0 94.9 95.1"
    xml:space="preserve"
  >
    <gradient-helper />

    <polygon
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      points="94.5,59.4 59.1,59.4 59.1,94.8,94.2,94.8"
    />

    <rect
      x="0.3"
      y="0.4"
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      width="35.4"
      height="94.3"
    />

    <rect
      x="59"
      y="0.3"
      fill="none"
      stroke="url(#svg-gradient)"
      stroke-width="0.7"
      stroke-miterlimit="10"
      width="35.4"
      height="35.4"
    />
  </svg>
</template>

<script>
import svg from '@/mixins/svg'
import GradientHelper from './GradientHelper.vue'
export default {
  components: { GradientHelper },
  mixins: [svg]
}
</script>
