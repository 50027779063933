<template>
  <v-container
    v-if="userWithNotificationSettings"
    class="pa-0 layout-container"
  >
    <NotificationSettings
      :user="userWithNotificationSettings"
      :refetch-user="refetchUser"
    />
  </v-container>
</template>

<script>
import NotificationSettings from './NotificationSettings.vue'
import USER_WITH_NOTIFICATION_SETTINGS from './UserWithNotificationSettings.gql'

export default {
  components: {
    NotificationSettings
  },
  data () {
    return {
      userWithNotificationSettings: null
    }
  },
  methods: {
    async refetchUser () {
      const { data: { userWithNotificationSettings } } = await this.$apollo.query({
        query: USER_WITH_NOTIFICATION_SETTINGS,
        fetchPolicy: 'no-cache'
      })
      this.userWithNotificationSettings = userWithNotificationSettings
    }
  },
  apollo: {
    userWithNotificationSettings: {
      query: USER_WITH_NOTIFICATION_SETTINGS,
      fetchPolicy: 'no-cache'
    }
  }
}
</script>
