<template>
  <div
    class="my-0 py-0"
  >
    <div>
      <!-- Title -->
      <div
        class="justify-space-between clickable pb-4 px-2 px-md-4"
        @click="isOpen = !isOpen"
      >
        <div class="d-flex align-center">
          <v-icon
            color="white"
            class="rounded-circle pa-1 icon-background"
            small
          >
            {{ icon }}
          </v-icon>

          <span class="pl-2 text-h6">{{ title }}</span>
          <v-spacer />
          <v-btn icon>
            <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </div>
        <div>
          {{ subtitle }}
        </div>
      </div>

      <!-- Content -->
      <v-expand-transition>
        <div v-show="isOpen">
          <v-divider />
          <slot />
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'mdi-cog'
    },
    isDefaultOpen: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isOpen: this.isDefaultOpen
    }
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.icon-background{
  background: rgb(127, 127, 127);
}
</style>
