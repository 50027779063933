<template>
  <v-container class="layout-container pa-0">
    <SenderSettings />
    <v-divider
      class="my-4"
    />
    <DomainSettings />
  </v-container>
</template>

<script>
import DomainSettings from './DomainSettings.vue'
import SenderSettings from './SenderSettings.vue'

export default {
  components: {
    DomainSettings,
    SenderSettings
  },
  computed: {
  },
  methods: {
  },
  apollo: {
  }
}
</script>
